%modal-dialog {
	position: fixed;
	background-color: rgb(0 0 0 / 5%);
	inset: 0;
	z-index: 999;
	visibility: hidden;
	opacity: 0;
	pointer-events: none;
	transition: all 0.3s;

	&.on {
		pointer-events: visible;
		opacity: 1;
		visibility: visible;
	}

	& > div {
		width: 300px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		padding: 0 20px 20px;
		background: #fff;
		box-shadow: 0 0 0 4000px rgb(0 0 0 / 30%);
	}

	header {
		text-align: center;
		font-size: 16px;
		margin: 40px 0;
	}

	footer {
		display: flex;
		margin-left: -20px;

		button {
			flex: 1;
			margin: 0 0 0 20px;
		}
	}
}
