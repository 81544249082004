@use '../designSystem/common/sassVars' as *;

.subscriptionSteps {
	font-size: 100%;

	@media (max-width: $scr-sm) {
		font-size: 80%;
	}

	@media (max-width: $scr-xs) {
		font-size: 70%;
	}

	strong {
		font-size: 3.125em;
		font-weight: 400;
		display: block;
		margin-bottom: 3vh;
		line-height: 1.25em;
	}

	figure {
		margin-bottom: 5vh;

		img {
			vertical-align: top;

			@media (max-width: $scr-sm) {
				max-width: 70%;
			}

			@media (max-width: $scr-xs) {
				max-width: 50%;
			}
		}

	}

	p {
		font-size: 1.25em;
		margin-bottom: 3vh;
	}

	&.update {
		max-width: none;
		text-align: left;

		@media (min-width: $scr-sm-min) {
			display: flex;
		}

		figure {
			flex: 1;
			text-align: center;
			margin-right: 40px;

			@media (max-width: $scr-sm) {
				display: none;
			}
		}

		form,
		div {
			flex: 2;

			&>* {
				max-width: 600px;
			}
		}

		strong {
			margin-bottom: 1em;
		}

		label {
			display: block;
			position: relative;
			padding-left: 30px;
			font-size: 1.25em;
			margin-bottom: 3vh;

			input {
				position: absolute;
				left: 0;
				top: 5px;
			}
		}

		button {
			display: inline-block;
			background-color: var(--primary-500);
			color: #fff;
			border-radius: 20px;
			padding: 0 30px;
			font-size: 14px;
			font-weight: 600;
			line-height: 40px;
			text-transform: uppercase;
			margin-top: 1.5em;
		}
	}
}
