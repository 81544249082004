@use "../designSystem/common/sassVars" as *;

.DLForm {
	background-color: #f2f2f2;
	margin: 0 auto;
	padding: 40px 40px 30px;
	position: relative;

	@media (min-width: $scr-sm-min) {
		width: 530px;
	}

	@media (max-width: $scr-sm) {
		margin: 0 -20px;
		padding: 20px;
	}

	dl {
		margin: 0;
	}

	dt {
		float: left;
		padding: 0 10px 10px 0;
		width: 130px;
		clear: left;

		label {
			opacity: .5;
			position: relative;
			top: 4px;
		}
	}

	dd {
		padding: 0 0 10px 150px;
		margin-left: 0;
	}

	select,
	textarea,
	input[type="text"],
	input[type="email"],
	input[type="password"] {
		width: 100%;
		margin-bottom: 10px;
	}

	textarea {
		margin-bottom: 30px;
	}

	input[type="text"]:focus,
	input[type="email"]:focus,
	textarea:focus,
	select:focus {
		background-color: #E3F6FE;
		border-color: #85b6cb
	}

	.checkbox {
		input[type="checkbox"] {
			float: left;
			position: relative;
			top: 3px
		}

		label {
			display: block;
			margin-left: 30px;
		}
	}

	@media (max-width: $scr-xs) {
		dt {
			float: none;
			padding: 0;
			width: auto;
		}

		dd {
			padding: 0;
			margin: 10px 0;
		}
	}
}

.article .DLForm {
	@media (min-width: $scr-sm-min) {
		width: auto;
	}
}
