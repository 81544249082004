@use '../designSystem/common/sassVars' as *;

.followers {
	background-color: var(--grey-100);
	padding: 20px 30px 30px;
	margin-top: 20px;

	@media (max-width: $scr-xs) {
		padding: 20px;
		margin: 20px -20px 0;
	}

	table {
		width: 100%;
	}

	tr {
		border-bottom: 1px solid var(--grey-300);

	}

	td {
		padding-top: 5px;
		padding-bottom: 5px;
		font-size: 16px;
	}

	th {
		font-size: 12px;
		line-height: 14px;
		color: var(--grey-700);
		padding-bottom: 6px;
		text-transform: uppercase;
		vertical-align: bottom;
	}

	th,
	td {
		padding-left: 10px;
		padding-right: 10px;

		&:last-child {
			padding-right: 0;
		}

		&:first-child {
			padding-left: 0;
		}
	}



	.colName {
		width: 100%;

		div {
			display: grid;
			grid-template-columns: 40px 1fr;
			column-gap: 20px;
			align-items: center;
		}

		@media (max-width: $scr-xs) {
			padding-left: 0;
		}
	}

	@media (max-width: $scr-xs) {
		td.colName {
			font-size: 16px;
			line-height: 18px;
			font-weight: 600;
		}
	}

	figure {
		position: relative;

		@media (max-width: $scr-xxs) {
			display: none;
		}

		.photo {
			border-radius: 50%;
			vertical-align: top;
			max-width: none;
		}

		.flag {
			position: absolute;
			bottom: 0;
			right: 0;
			outline: 1px solid #fff;
		}
	}

	.colUnfollow {
		text-align: right;
	}

	.colNotif {
		text-align: center;
	}

	label {
		background-color: rgb(var(--grey-700) / 50%);
		width: 29px;
		height: 12px;
		border-radius: 6px;
		display: inline-block;
		position: relative;
		cursor: pointer;

		&::after {
			content: '';
			position: absolute;
			border-radius: 50%;
			top: -2px;
			left: 0;
			background-color: var(--grey-700);
			width: 16px;
			height: 16px;
			transition: all .2s;
		}
	}

	.toggler:checked~label {
		background-color: rgb(var(--primary-500) / 50%);

		&::after {
			background-color: var(--primary-500);
			transform: translateX(100%);
		}
	}
}
