@use '../../designSystem/common/sassVars' as *;

%sticky-icon-nav-bar {
	background-color: #fff;
	box-shadow: none;

	&>ul {
		box-shadow: 0 -1px 0 inset var(--grey-300);
		max-width: none;
	}

	&>ul>li {
		&:last-child a {
			margin-right: 0;
		}

		&>a {
			display: flex;
			align-items: center;
			column-gap: 8px;
			margin-right: 25px;

			&.active::before {
				filter: invert(30%) sepia(92%) saturate(6431%) hue-rotate(199deg) brightness(97%) contrast(101%);
			}
		}
	}

	li a {
		&::before {
			content: '';
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			width: 20px;
			height: 20px;
			display: block;
			filter: invert(29%) sepia(0%) saturate(4817%) hue-rotate(316deg) brightness(79%) contrast(83%);
		}

		&:not(.active):hover::before {
			filter: none;
		}

	}

	a {
		span:last-of-type {
			display: none;
		}

		@media (max-width: $scr-sm) {
			span:first-of-type {
				display: none;
			}

			span:last-of-type {
				display: inline-block;
			}
		}
	}

	&.isSticky {
		z-index: 5;
		position: sticky;

		&>ul>li {
			&>a,
			.showDropdown {
				line-height: 48px;
			}

			.showDropdown::after {
				height: 48px;
			}

		}

		@media (max-width: 1100px) and (min-width: $scr-lg-min) {
			left: 30px;
			right: 355px;
		}

		@media (max-width: $scr-lg) and (min-width: $scr-md-min) {
			left: 20px;
			right: 340px;
		}

	}
}
