#user {
	.pn-form {
		margin: 20px auto;
		padding-bottom: 1px;
	}

	.remind-password-sent .complete-icon {
		width: 137px;
		height: 137px;
		background-image: url('../../../web/img/svg/userComplete.svg');
		background-size: contain;
		margin: 9px auto 14px;
	}

	.remind-password-sent {
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.remind-password-sent span {
		font-size: 32px;
		font-weight: 600;
		color: var(--green-500);
		margin-bottom: 20px;
	}

	.remind-password-sent p {
		font-size: 13px;
		color: #000;
	}
}

#formSignUp {
	.ds-formCheckbox {
		margin-bottom: 10px;
	}
}
