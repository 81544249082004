@use '../designSystem/common/sassVars' as *;

@media (max-width: $scr-sm) {
	.scrollableNav.navMore:not(.stickyIconNavBar) {
		z-index: 0;

		ul {
			display: inline-flex;
		}
	}

	.scrollableNav.navMore {
		overflow-y: auto;
		margin-left: -20px;
		margin-right: -20px;
		padding: 0 20px;
		scroll-snap-type: x mandatory;
		width: calc(100% + 40px);

		ul {
			flex-wrap: nowrap;
			align-items: flex-end;
			justify-content: space-between;

			li {
				scroll-snap-align: center;
			}
		}
	}
}
