@use '../designSystem/common/sassVars' as *;

#profile {
	h2 {
		margin-bottom: 5px;

		@media (max-width: $scr-xs) {
			margin-top: -10px;
		}

		span {
			font-size: 14px;
			margin-left: 10px;
			font-weight: 600;

			@media (max-width: $scr-sm) {
				display: block;
			}

		}
	}

	.DLForm {
		width: auto;
		margin-bottom: 30px;

		dd {
			margin-bottom: 20px;
		}

		dd:last-child {
			margin-bottom: 0;
		}

		select,
		.year * {
			width: auto !important;
			max-width: 100%;
		}

		input[type="text"],
		input[type="password"] {
			width: 360px;
			max-width: 100%;
		}

		textarea {
			margin-bottom: 0;
		}
	}

	.avatar {
		position: relative;
		display: inline-block;

		img {
			border: 1px solid #d7d7d7;
			display: block;
		}
	}
}

.socialConnection {
	width: 100%;
	border-bottom: 1px solid var(--grey-300);

	td {
		border-top: 1px solid var(--grey-300);
		padding: 10px 0;

		&:last-child {
			text-align: right;
		}

		&:first-child {
			font-size: 13px;
		}

	}

	&__logo {
		vertical-align: -9px;
		margin-right: 12px;

		&--twitter {
			background-color: var(--twitter-color);
			padding: 5px;
			height: 30px;
			width: 30px;
		}
	}


	@media (max-width: $scr-xxs) {
		span,
		b {
			display: none;
		}
	}
}
