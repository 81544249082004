@use "../designSystem/common/sassVars" as *;
@use "forms";
@use "profileSettings";
@use "subscribeSteps";
@use "followers";
@use "../components/export/modalDialog";
@use "../components/livesearch";
@use "../components/export/navMore" as *;
@use "../components/export/stickyIconNavBar";
@use "../components/scrollableNav";

.liveSearch {
	@media (min-width: $scr-md-min) {
		max-width: 40%;
		margin-left: auto;
		margin-right: auto;
	}
}

#modalDialog {
	@extend %modal-dialog;
}

.navMore {
	@include nav-more;
	@extend %sticky-icon-nav-bar;

	$tabs: ico__profile,
	ico__password,
	ico__mystack;

	@each $tab in $tabs {
		li.#{$tab} a::before {
			background-image: url('../../../web/img/profile/tabIcons/#{$tab}.svg');
		}
	}
}
