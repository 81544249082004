.liveSearch {
	width: 100%;
	margin-bottom: 30px;
	position: relative;
	clear: both;

	&.open {
		input[type="text"] {
			border-color: var(--grey-600);
			border-bottom-left-radius: 0;
		}

		button[type="submit"] {
			border-bottom-right-radius: 0;
		}

		button[type="reset"] {
			display: block;
		}
	}

	&__input {
		display: grid;
		grid-template-columns: 1fr auto;

		input {
			width: 100%;
			border-radius: 4px;
			line-height: 46px;
			height: 46px;
			border: solid 1px var(--grey-300);
			padding: 0 16px;
			margin: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;

			&:focus {
				border-color: var(--grey-600);
			}
		}

		button[type="submit"] {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			min-width: 0;
			width: 66px;
			text-align: center;

			img {
				vertical-align: top;
			}

			&:focus::after {
				content: normal;
			}
		}

		button[type="reset"] {
			display: none;
			position: absolute;
			top: 0;
			right: 66px;
			height: 46px;
			width: 46px;
			background: url('../../../web/img/icons/close-black-round.svg') no-repeat center /14px 14px;
		}

	}

	&--noresultsPage {
		input {
			border-radius: 4px;
		}

		button[type="reset"] {
			right: 5px;
		}
	}

	#search-result-suggestions {
		position: absolute;
		width: 100%;
	}

	.suggestion-list {
		list-style: none;
		background-color: #fff;
		border: 1px solid var(--grey-600);
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
		position: relative;
		top: -1px;
		padding: 8px 0;
		overflow-y: auto;
		max-height: 400px;
		overscroll-behavior: contain;
		z-index: 2;

		li {
			padding: 0;
		}

		a {
			display: grid;
			align-items: center;
			padding: 8px 15px;
			color: #000;
			text-decoration: none;
			font-size: 14px;
			column-gap: 16px;

			&:hover {
				background-color: var(--grey-100);
			}

			img {
				border-radius: 50%;
			}
		}
	}

	&--photo .suggestion-list a {
		grid-template-columns: 46px auto;
	}
}
